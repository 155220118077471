import { PageLoading } from '@amzn/sitc-frontend/components';
import { useIdToken, useUserPoolMicroAppName } from '@amzn/sitc-frontend/contexts';
import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import React, { ReactNode, useContext, useMemo } from 'react';

import { appSyncConfig } from '../configs/appSync';
import { Stage } from '../constants/enums';
import { MicroAppConfigContext } from '../utils/app';

type Props = {
  stage: Stage;
  children: ReactNode;
};

export const ApolloClientProvider = ({ stage, children }: Props) => {
  const idToken = useIdToken();
  const userPoolMicroAppName = useUserPoolMicroAppName() || '*';
  const { appId } = useContext(MicroAppConfigContext);
  const { endpoint } = appSyncConfig[stage];

  const apolloClient = useMemo(() => {
    if (!idToken) {
      return null;
    }

    // This remove "__typename" fields when reusing data from a query as an argument to another GraphQL operation.
    // More details: https://www.apollographql.com/docs/react/api/link/apollo-link-remove-typename/#remove-__typename-from-all-variables
    const removeTypenameLink = removeTypenameFromVariables();

    const httpLink = createHttpLink({ uri: endpoint });

    const authLink = setContext((operation, { headers }) => ({
      headers: {
        ...headers,
        appid: appId,
        authorization: `Bearer ${idToken}`,
        'micro-app-name': userPoolMicroAppName,
      },
    }));

    return new ApolloClient({
      link: ApolloLink.from([removeTypenameLink, authLink, httpLink]),
      cache: new InMemoryCache(),
    });
  }, [idToken, userPoolMicroAppName]);

  if (!apolloClient) {
    return <PageLoading />;
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
